/**
 *
 * Event Page
 *
 */

import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route } from 'react-router-dom';

import { NotFoundPage } from '../../components/NotFoundPage/Loadable';
import EventAlbumListPage from './components/EventAlbumListPage';
import EventAlbumFormPage from './components/EventAlbumFormPage';

interface Props {}

export const EventAlbumPage = memo((props: Props) => {
  return (
    <>
      <Helmet>
        <title>Event Album Page - JPOA</title>
        <meta name="description" content="" />
      </Helmet>
      <Switch>
        <Route exact path="/event-album" component={EventAlbumListPage} />
        <Route exact path="/event-album/new" component={EventAlbumFormPage} />
        <Route
          exact
          path="/event-album/edit/:eventAlbumid"
          component={EventAlbumFormPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
});
