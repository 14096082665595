import Axios from './index';
import { ListResult, HGKO } from '../Models';

export const getHGKOList = async params => {
  const { data } = await Axios.get<ListResult<HGKO>>('/hgko', {
    params,
  });
  return data;
};

export const importHGKO = async (fd: FormData) => {
  const { data } = await Axios.post('/hgko/import', fd, {
    timeout: 1000 * 600,
  });
  return data;
};
