import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import { ErrorMessage } from 'formik';

// material core
import { IconButton } from '@material-ui/core';

//material icons
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { LocalFile } from '../../../Models';
import { IMAGE_URL, SECURE_IMAGE_URL } from '../../../constant';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  coverImageContainer: {
    width: '100%',
    height: 200,
    borderRadius: 4,
    marginBottom: 20,
    position: 'relative',
  },
  coverImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 4,
  },
  coverImageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  closeBtnContainer: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    left: 0,
    right: 0,
    bottom: 0,
    height: 48,
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0px 0 5px 0',
  },
  dropzoneWrapper: {
    width: '100%',
    height: 225,
  },
  dropzoneContainer: {
    width: '100%',
    height: 200,
    border: '1px dotted rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    backgroundColor: '#fbfbfb',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneText: {
    fontSize: 26,
    color: 'rgb(204, 204, 204)',
    margin: '5px 0px 2px 0px',
  },
  dropzoneSubText: {
    fontSize: 12,
    color: 'rgb(204, 204, 204)',
  },
  noteIcon: {
    fontSize: 60,
    color: 'rgb(204, 204, 204)',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
}));

type Props = {
  image: LocalFile;
  isSecure?: boolean;
  fieldName: string;
  deleteOnClick: () => void;
  fileOnDrop: (file: LocalFile) => void;
  title: string;
};

function ImageUploader(props: Props) {
  const classes = useStyles();
  const {
    image,
    isSecure,
    fieldName,
    deleteOnClick,
    fileOnDrop,
    title,
  } = props;
  const URL = isSecure ? SECURE_IMAGE_URL : IMAGE_URL;
  if (image && image.preview) {
    return (
      <div className={classes.coverImageWrapper}>
        <div className={classes.label}>{title || '圖片'}</div>
        <div className={classes.coverImageContainer}>
          <div className={classes.closeBtnContainer}>
            <IconButton color="secondary" onClick={() => deleteOnClick()}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
          <img
            src={image && image.size ? image.preview : URL + image.preview}
            alt="cover"
            className={classes.coverImage}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={classes.dropzoneWrapper}>
      <Dropzone onDrop={(file: any) => fileOnDrop(file)}>
        {({ getRootProps, getInputProps }) => (
          <div className={classes.dropzoneContainer}>
            <div {...getRootProps()} className={classes.dropzone}>
              <input {...getInputProps()} />
              <NoteAddOutlinedIcon className={classes.noteIcon} />
              <div className={classes.dropzoneText}>{title || '圖片'}</div>
              <div className={classes.dropzoneSubText}>
                點擊或拉動圖片到此處
              </div>
            </div>
          </div>
        )}
      </Dropzone>
      <ErrorMessage name={fieldName}>
        {msg => <div className={classes.errmsg}>{msg}</div>}
      </ErrorMessage>
    </div>
  );
}

export default ImageUploader;
