import Axios from './index';
import { File } from '../Models';

export const uploadFile = async fd => {
  const { data } = await Axios.post<File>('/file/file', fd, {
    timeout: 100000,
  });
  return data;
};

export const uploadSecureFile = async fd => {
  const { data } = await Axios.post<File>('/file/secure', fd, {
    timeout: 1000 * 150,
  });
  return data;
};

export const getSecureFile = async fileid => {
  const { data } = await Axios.get<File>(`/v1/file/secure/${fileid}`);
  return data;
};
