export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const pdfToPng = (fileName: string) => {
  if (fileName) {
    const tempString = fileName.split('.');
    return tempString[0] + '.png';
  }
  return '';
};

export const phoneRegExp = /^[23569][0-9]{7}$/;

export const DOCUMENT_TABLE_COLUMN = [
  // { name: '封面圖片', field: 'coverImage', isSecureImage: true },
  { name: '標題', field: 'title', isImage: false },
  { name: '類型', field: 'category', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const MEDICAL_TABLE_COLUMN = [
  { name: '文件編號', field: 'medicalid', isImage: false },
  { name: '封面圖片', field: 'coverImage', isSecureImage: true },
  { name: '創建日期', field: 'date', isImage: false },
];

export const MEDICAL_CONSULTATION_TABLE_COLUMN = [
  { name: '顧問名稱', field: 'name', isImage: false },
  { name: '專科', field: 'specialty', isImage: false },
  { name: '地區', field: 'region', isImage: false },
  { name: '電話號碼', field: 'phone', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const EVENT_TABLE_COLUMN = [
  { name: '封面圖片', field: 'coverImage', isSecureImage: true },
  { name: '標題', field: 'title', isImage: false },
  { name: '類型', field: 'category', isImage: false },
  { name: '活動日期', field: 'eventDate', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const EVENT_ALBUM_TABLE_COLUMN = [
  { name: '封面圖片', field: 'coverImage', isSecureImage: true },
  { name: '活動標題', field: 'eventName', isImage: false },
  { name: '活動日期', field: 'eventDate', isImage: false },
  { name: 'URL', field: 'url', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const NEWS_TABLE_COLUMN = [
  { name: '預覽圖片', field: 'coverImage', isImage: true },
  { name: '標題', field: 'title', isImage: false },
  { name: '類型', field: 'newsType', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const NEWS_LETTER_TABLE_COLUMN = [
  { name: '封面圖片', field: 'coverImage', isImage: true },
  { name: '標題', field: 'title', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const SURVEY_TABLE_COLUMN = [
  { name: '問卷標題', field: 'title', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const SURVEY_SUBMISSION_TABLE_COLUMN = [
  { name: '預覽圖片', field: 'coverImage', isSecureImage: true },
  { name: '問卷標題', field: 'title', isImage: false },
  { name: '會員', field: 'userName', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const WELFARE_TABLE_COLUMN = [
  { name: '封面圖片', field: 'coverImage', isSecureImage: true },
  { name: '標題', field: 'title', isImage: false },
  { name: '類型', field: 'category', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const MESSAGE_TABLE_COLUMN = [
  // { name: '會員編號', field: 'memberId', isImage: false },
  { name: '會員編號', field: 'phone', isImage: false },
  { name: '會員', field: 'nick', isImage: false },
  { name: '會員訊息', field: 'message', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const HGKO_TABLE_COLUMN = [
  { name: 'HGKO ID', field: 'hgkoid', isImage: false },
  { name: '帳戶名稱', field: 'accountName', isImage: false },
  { name: '密碼', field: 'password', isImage: false },
  { name: 'Pcode', field: 'pcode', isImage: false },
  { name: '創建日期', field: 'date', isImage: false },
];

export const renderRowKey = (type: string) => {
  switch (type) {
    case 'document':
      return 'documentid';
    case 'medical':
      return 'medicalid';
    case 'event':
      return 'eventid';
    case 'news':
      return 'newsid';
    case 'newsletter':
      return 'newsletterid';
    case 'welfare':
      return 'welfareid';
    default:
      return '';
  }
};

export const renderItemTitle = (type: string) => {
  switch (type) {
    case 'document':
      return '表格下載';
    case 'medical':
      return '醫事顧問';
    case 'event':
      return '協會活動';
    case 'news':
      return '協會訊息';
    case 'newsletter':
      return '協會會訊';
    case 'welfare':
      return '會員福利';
    case 'image':
      return '圖片橫額';
    case 'others':
      return '其他';
    case 'link':
      return '外部連結';
    // case 'hgko':
    //   return 'HGKO';
    default:
      return '';
  }
};
