import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// material ui icon
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import StorefrontIcon from '@material-ui/icons/Storefront';
import EventNoteIcon from '@material-ui/icons/EventNote';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import LocalHospitalOutlinedIcon from '@material-ui/icons/LocalHospitalOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import PollIcon from '@material-ui/icons/PollOutlined';
import MessageIcon from '@material-ui/icons/Message';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

// custom items
import MenuItemButton from '../MenuItemButton';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 0,
    margin: '0px 0px',
  },
  menuItem: {
    width: '100%',
    padding: '8px 16px',
  },
  links: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
  },
  aciveLinks: {
    borderRight: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgba(12, 18, 48, 0.1)',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'cener',
    justifyContent: 'flex-start',
  },
}));

function MenuItems({ setOpen, profile }) {
  const style = useStyles();
  return (
    <ul className={style.root}>
      <NavLink
        to="/banner"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <WallpaperIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="橫額管理"
        />
      </NavLink>

      <NavLink
        to="/news"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <NotificationsNoneIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="協會訊息"
        />
      </NavLink>
      <NavLink
        to="/newsletter"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <EmailOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="協會會訊"
        />
      </NavLink>

      <NavLink
        to="/welfare"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <StorefrontIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="會員福利"
        />
      </NavLink>

      <NavLink
        to="/event"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <EventNoteIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="協會活動"
        />
      </NavLink>
      <NavLink
        to="/event-album"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <FolderSpecialIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="相片分享"
        />
      </NavLink>
      <NavLink
        to="/document"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <AssignmentOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="表格下載"
        />
      </NavLink>

      {/* <NavLink
        to="/medical"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <LocalHospitalOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="醫事顧問(deprecated)"
        />
      </NavLink> */}

      <NavLink
        to="/medical-consultation"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <LocalHospitalOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="醫事顧問"
        />
      </NavLink>

      <NavLink
        to="/survey"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <PollIcon style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }} />
          }
          label="問卷調查"
        />
      </NavLink>

      <NavLink
        to="/notification"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <SendOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="推送訊息"
        />
      </NavLink>

      <NavLink
        to="/hgko"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <AccountBalanceWalletIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="HGKO"
        />
      </NavLink>

      <NavLink
        to="/member"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <RecentActorsIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="會員管理"
        />
      </NavLink>
      <NavLink
        to="/message"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <MessageIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="會員訊息"
        />
      </NavLink>
      <NavLink
        to="/admin"
        className={style.links}
        activeClassName={style.aciveLinks}
        onClick={() => setOpen(false)}
      >
        <MenuItemButton
          icon={
            <SupervisorAccountOutlinedIcon
              style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)' }}
            />
          }
          label="管理員"
        />
      </NavLink>
    </ul>
  );
}

export default MenuItems;
