import Axios from './index';
import { ListResult, EventAlbumResponse } from 'Models';

export interface GetEventAlbumListParams {
  limit: number;
  offset: number;
  q?: string;
}

export const getEventAlbumList = async (params: GetEventAlbumListParams) => {
  const { data } = await Axios.get<ListResult<EventAlbumResponse>>(
    '/eventAlbum',
    {
      params,
    },
  );
  return data;
};

export const getSingleEventAlbum = async (id: string) => {
  const { data } = await Axios.get<EventAlbumResponse>(`/eventAlbum/${id}`);
  return data;
};

export interface CreateEventAlbumParams {
  eventName: string;
  coverImage: string;
  eventDate: string;
  url: string;
}

export const createEventAlbum = async (param: CreateEventAlbumParams) => {
  const { data } = await Axios.post<EventAlbumResponse>('/eventAlbum', param);
  return data;
};

export const updateEventAlbum = async (
  eventAlbumid: string | number,
  param: CreateEventAlbumParams,
) => {
  const { data } = await Axios.patch<EventAlbumResponse>(
    `/eventAlbum/${eventAlbumid}`,
    param,
  );
  return data;
};

export const deleteEventAlbum = async (eventAlbumid: string | number) => {
  await Axios.delete(`/eventAlbum/${eventAlbumid}`);
};
