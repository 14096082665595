import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router-dom';

//material icons
import DeleteIcon from '@material-ui/icons/Delete';

// material core
import {
  IconButton,
  LinearProgress,
  CircularProgress,
  Button,
  Paper,
} from '@material-ui/core';

import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

import ImageUploader from '../../../components/ImageUploader';
import DatePicker from '../../../components/FormDatePicker';
import Input from '../../../components/FormInput';
import { uploadSecureFile } from '../../../../Services/FileService';
import Dialog from '../../../components/Dialog';
import moment from 'moment';
import {
  createEventAlbum,
  CreateEventAlbumParams,
  deleteEventAlbum,
  getSingleEventAlbum,
  updateEventAlbum,
} from 'Services/EventAlbumServices';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  gridRowContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px !important',
  },
  formContainer: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 16,
    margin: 16,
    position: 'relative',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 600,
    // height: 500,
    margin: 20,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  fieldCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  headerTextRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 10,
    width: 640,
  },
  bubbleWrapper: {
    width: 26,
    height: 26,
    borderRadius: 13,
    backgroundColor: '#ddd',
  },
  bubbleText: {
    color: 'white',
    fontSize: 20,
    fontWeight: 700,
  },
  headerText: {
    fontSize: 20,
    color: '#2e2e2e',
    fontWeight: 700,
    marginLeft: 20,
  },
  addressCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  addressLink: {
    fontSize: 14,
    color: '#2e2e2e',
    fontWeight: 700,
  },
  addressTime: {
    fontSize: 16,
    color: 'rgba(51,51,51,0.6)',
    fontWeight: 500,
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    backgroundColor: '#2478FF',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 20,
    height: 40,
    '&:hover': {
      backgroundColor: 'rgba(36, 120, 255, 0.8)',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteButton: {
    width: '100%',
    backgroundColor: '#e74c3c',
    color: 'white',
    boxShadow: 'none!important',
    cursor: 'pointer',
    borderRadius: 24,
    height: 48,
    '&:hover': {
      backgroundColor: '#c0392b',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  btn: {
    width: '100%',
  },
  chipRow: {
    width: '100%',
    padding: `${theme.spacing(1)}px 0px !important`,
    flexWrap: 'wrap',
    display: 'flex',
  },
  chipRoot: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
  dropzoneWrapper: {
    width: '100%',
    height: 225,
  },
  dropzoneContainer: {
    width: '100%',
    height: 200,
    border: '1px dotted rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
    backgroundColor: '#fbfbfb',
  },
  dropzone: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toggleBtnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  htmlContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0px 0 5px 0',
  },
  coverImageContainer: {
    width: '100%',
    height: 200,
    borderRadius: 4,
    marginBottom: 20,
    position: 'relative',
  },
  coverImage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderRadius: 4,
  },
  coverImageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  closeBtnContainer: {
    position: 'absolute',
    top: -25,
    right: -25,
  },
  noteIcon: {
    fontSize: 60,
    color: 'rgb(204, 204, 204)',
  },
  dropzoneText: {
    fontSize: 26,
    color: 'rgb(204, 204, 204)',
    margin: '5px 0px 2px 0px',
  },
  dropzoneSubText: {
    fontSize: 12,
    color: 'rgb(204, 204, 204)',
  },
  pdfContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  pdfWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  pdfTextRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '10px 0px 20px 0px',
  },
  pdfIcon: {
    fontSize: 50,
    color: '#34495e',
  },
  pdfTextCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    margin: '0px 0px 0px 10px',
  },
  pdfText: {
    width: '400px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  pdfSubText: {
    width: '200px',
    margin: '0px 0px 3px 0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  btnRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  deleteButtonContainer: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  imagesGridList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: '#ffffff',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  imageDeleteIcon: {
    color: '#fffff',
  },
});

type File = {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  preview: null | undefined | any | string;
};

type FormItem = {
  eventName: string;
  coverImage: any | File[];
  eventDate: string | Date;
  url: string;
};

const FORM_ITEM = {
  eventName: '',
  coverImage: null,
  eventDate: '',
  url: '',
};

const Schema = Yup.object().shape({
  eventName: Yup.string()
    .required('必填項目')
    .min(2, '最少要2個字元')
    .nullable()
    .max(100, '不可多於100個字元'),
  coverImage: Yup.mixed().required('請上載封面圖片'),
  eventDate: Yup.string().required('必填項目'),
  url: Yup.string()
    .required('必填項目')
    .url('請輸入正確的URL')
    .test('https-check', 'URL 必須以 https:// 開頭', value => {
      return value ? value.startsWith('https://') : false;
    })
    .max(1000, '不可多於1000個字元'),
});

type Props = WithStyles<typeof styles>;
type State = {
  form: FormItem;
  loading: boolean;
  formLoading: boolean;
  eventAlbumid: number | null;
  deleteVisible: boolean;
  deleteLoading: boolean;
};

type TParams = {
  eventAlbumid: string;
};

interface EventAlbumProps {
  eventName: string;
  coverImage: string;
  eventDate: string | Date;
  url: string;
}

class EventAlbumFormPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      form: { ...FORM_ITEM },
      loading: false,
      formLoading: true,
      eventAlbumid: null,
      deleteVisible: false,
      deleteLoading: false,
    };
    this.imageOnDrop = this.imageOnDrop.bind(this);
    this.editorImageUpload = this.editorImageUpload.bind(this);
    this.editorImageValidation = this.editorImageValidation.bind(this);
    this.deleteOnPress = this.deleteOnPress.bind(this);
    this.deleteOnClose = this.deleteOnClose.bind(this);
    this.deleteConfirm = this.deleteConfirm.bind(this);
  }

  componentDidMount() {
    this.initEventForm();
  }

  async initEventForm() {
    const { match } = this.props;
    const { params } = match;
    try {
      let eventAlbumRes: any = null;
      if (params && params.eventAlbumid) {
        eventAlbumRes = await getSingleEventAlbum(params.eventAlbumid);
        console.log({ eventAlbumRes });
        const {
          eventName,
          coverImage,
          eventDate,
          url,
          eventAlbumid,
        } = eventAlbumRes;
        this.setState({
          eventAlbumid,
          formLoading: false,
          form: {
            eventName,
            coverImage: coverImage
              ? {
                  preview: coverImage,
                }
              : null,
            eventDate: new Date(eventDate),
            url,
          },
        });
      } else {
        this.setState({ formLoading: false });
      }
    } catch (err) {
      console.log(err);
      toast('找不到相片分享，請返回上一頁。');
    }
  }

  async createNewEventAlbum(values) {
    const { history } = this.props;
    this.setState({ loading: true });
    const { eventName, coverImage, eventDate, url } = values;
    try {
      const eventAlbumParams: CreateEventAlbumParams = {
        eventName,
        coverImage: '',
        eventDate: eventDate ? moment(eventDate).format('YYYY-MM-DD') : '',
        url,
      };
      const imagePromiseArray: any[] = [];
      if (coverImage) {
        const formData = new FormData();
        formData.append('file', coverImage);
        imagePromiseArray.push(uploadSecureFile(formData));
      }
      const imagesRes = await Promise.all(imagePromiseArray);
      if (imagesRes && imagesRes.length > 0) {
        imagesRes.forEach((res, index) => {
          if (index === 0) {
            eventAlbumParams.coverImage = res.fileName;
          }
        });
      }
      await createEventAlbum(eventAlbumParams);
      this.setState({ loading: false });
      toast('新增相片分享成功。');
      history.push('/event-album');
    } catch (err) {
      this.setState({ loading: false });
      toast('新增檔案失敗，請重試。');
      console.log(err);
    }
  }

  async updateEventAlbum(values) {
    const { eventAlbumid } = this.state;
    this.setState({ loading: true });
    const { eventName, coverImage, eventDate, url } = values;
    try {
      const eventAlbumParams: CreateEventAlbumParams = {
        eventName,
        coverImage: '',
        eventDate: eventDate ? moment(eventDate).format('YYYY-MM-DD') : '',
        url,
      };
      if (coverImage && coverImage.size) {
        const formData = new FormData();
        formData.append('file', coverImage);
        const fileRes = await uploadSecureFile(formData);
        eventAlbumParams.coverImage = fileRes.fileName;
      } else {
        eventAlbumParams.coverImage = coverImage.preview;
      }

      await updateEventAlbum(eventAlbumid!, eventAlbumParams);
      this.setState({ loading: false });
      toast('編輯相片分享成功。');
    } catch (err) {
      this.setState({ loading: false });
      toast('編輯相片分享失敗，請重試。');
      console.log(err);
    }
  }

  onSubmit(values) {
    const { eventAlbumid } = this.state;
    if (eventAlbumid) {
      this.updateEventAlbum(values);
    } else {
      this.createNewEventAlbum(values);
    }
  }

  imageOnDrop(file, setFieldValue) {
    if (file[0].size > 1024 * 10000) {
      toast('封面圖片不可大於10MB');
      return;
    }
    if (
      file[0].type === 'image/jpeg' ||
      file[0].type === 'image/jpg' ||
      file[0].type === 'image/png'
    ) {
      file[0]['preview'] = URL.createObjectURL(file[0]);
      setFieldValue('coverImage', file[0]);
    } else {
      toast('封面圖片只接受.jpg / .png檔案');
      return;
    }
  }

  async editorImageUpload(param) {
    const formData = new FormData();
    const { file, progress, success, error } = param;
    formData.append('file', file);
    try {
      const fileRes = await uploadSecureFile(formData);
      progress(1);
      success({
        url: fileRes.url + fileRes.fileName,
      });
    } catch (err) {
      error({
        msg: 'Fail to upload image, please try again.',
      });
    }
  }

  editorImageValidation(file) {
    if (file.size > 1024 * 10000) {
      toast('封面圖片不可大於10MB');
    }
    return file.size < 1024 * 10000;
  }

  coverImageOnDelete(setFieldValue) {
    setFieldValue('coverImage', null);
  }

  deleteOnPress() {
    this.setState({ deleteVisible: true });
  }

  deleteOnClose() {
    this.setState({ deleteVisible: false });
  }

  async deleteConfirm() {
    const { eventAlbumid } = this.state;
    const { history } = this.props;
    this.setState({ deleteLoading: true });
    try {
      await deleteEventAlbum(eventAlbumid!);
      toast('成功刪除相片分享');
      this.setState({ deleteVisible: false, deleteLoading: true });
      history.replace('/event');
    } catch (err) {
      toast('刪除相片分享失敗，請重試。');
      this.setState({ deleteVisible: false, deleteLoading: true });
    }
  }

  render() {
    const { classes } = this.props;
    const {
      form,
      loading,
      formLoading,
      eventAlbumid,
      deleteVisible,
      deleteLoading,
    } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>{eventAlbumid ? '編輯' : '新增'}相片分享 - JPOA</title>
          <meta name="description" content="" />
        </Helmet>
        <div className={classes.root}>
          <div className={classes.loadingBar}>
            {formLoading ? <LinearProgress color="secondary" /> : null}
          </div>
          {formLoading ? null : (
            <Formik
              initialValues={form}
              validationSchema={Schema}
              onSubmit={values => this.onSubmit(values)}
            >
              {({
                values,
                handleBlur,
                handleChange,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form className={classes.formRoot}>
                  <Paper className={classes.formContainer}>
                    {eventAlbumid ? (
                      <div className={classes.deleteButtonContainer}>
                        <IconButton onClick={this.deleteOnPress}>
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ) : null}
                    <div className={classes.formWrapper}>
                      <h2>{eventAlbumid ? '編輯' : '新增'}相片分享</h2>
                      <ImageUploader
                        image={values.coverImage}
                        title="封面圖片"
                        isSecure
                        fieldName="coverImage"
                        deleteOnClick={() => {
                          this.coverImageOnDelete(setFieldValue);
                        }}
                        fileOnDrop={file =>
                          this.imageOnDrop(file, setFieldValue)
                        }
                      />
                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="eventName"
                        label="活動標題"
                        placeholder="活動標題"
                        width="100%"
                        disableAutoComplete
                      />

                      <DatePicker
                        label="活動日期"
                        value={values.eventDate}
                        errors={errors}
                        touched={touched}
                        fieldName="eventDate"
                        onChange={e => setFieldValue('eventDate', e)}
                        minDate={null}
                      />

                      <Input
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name="url"
                        label="URL"
                        placeholder="URL"
                        width="100%"
                        disableAutoComplete
                      />

                      <Button
                        type="submit"
                        style={{ width: '100%', marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        <div>
                          {loading ? (
                            <CircularProgress color="primary" size={22} />
                          ) : (
                            '提交'
                          )}
                        </div>
                      </Button>
                    </div>
                  </Paper>
                </Form>
              )}
            </Formik>
          )}
          <Dialog
            open={deleteVisible}
            handleClose={this.deleteOnClose}
            title="刪除相片分享"
            okBtnText="刪除"
            okBtnAction={this.deleteConfirm}
            cancelOnPress={this.deleteOnClose}
            confirmLoading={deleteLoading}
          >
            <div>確定要刪除相片分享(編號{eventAlbumid})?</div>
          </Dialog>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(EventAlbumFormPage));
