import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import { zhTW } from 'date-fns/locale';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import './DatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  label: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(51,51,51, 0.8)',
    padding: '0 0 5px 0',
  },
  pcikerCol: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 85,
  },
  pickerSeperator: {
    width: 20,
    textAlign: 'center',
  },
  dateBtn: {
    width: '100%',
    padding: '10px 18px',
    borderRadius: 6,
    height: 40,
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:focus': {
      outline: 'none',
    },
  },
  dateBtnText: {
    width: '100%',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.55)',
    fontSize: 16,
    fontWeight: 400,
  },
  dateBtnBoldText: {
    fontSize: 16,
    width: '100%',
    textAlign: 'left',
    color: '#000000DE',
    fontWeight: 400,
    height: 24,
  },
  pickerRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
  },
  errmsg: {
    color: '#e74c3c',
    fontSize: 12,
    padding: '5px 0 5px 0',
    textAlign: 'right',
    width: '100%',
  },
}));

const CustomDatePicker = ({
  value,
  onChange,
  label,
  errors,
  touched,
  fieldName,
  disabled,
  isClearable,
  minDate,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.pcikerCol}>
      <div className={classes.label}>{label}</div>
      <DatePicker
        selected={value}
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
        locale={zhTW}
        isClearable={isClearable}
        minDate={minDate}
        customInput={
          <Button className={classes.dateBtn}>
            {value ? (
              <div
                className={classes.dateBtnBoldText}
                style={disabled ? { color: 'rgba(51,51,51, 0.5)' } : {}}
              >
                {`${moment(value).format('DD/MM/YYYY')}`}
              </div>
            ) : (
              <div
                className={classes.dateBtnText}
                style={disabled ? { color: 'rgba(51,51,51, 0.5)' } : {}}
              >
                {label}
              </div>
            )}
          </Button>
        }
        startDate={value}
        disabled={disabled}
      />
      {errors && touched && errors[fieldName] && touched[fieldName] ? (
        <div className={classes.errmsg}>{errors[fieldName]}</div>
      ) : null}
    </div>
  );
};

CustomDatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default CustomDatePicker;
