import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import * as _ from 'lodash';

// material core
import {
  LinearProgress,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
} from '@material-ui/core';

// material icon
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

//material style
import {
  withStyles,
  WithStyles,
  Theme,
  StyleRules,
} from '@material-ui/core/styles';

// custom items
import Table from '../../../components/CustomTable';
import Pagination from '../../../components/Pagination';

import { EventAlbumResponse } from '../../../../Models';
import { EVENT_ALBUM_TABLE_COLUMN } from '../../../../utils/common';
import { PAGE_SIZE } from '../../../../constant';
import {
  getEventAlbumList,
  GetEventAlbumListParams,
} from 'Services/EventAlbumServices';

const styles = (theme: Theme): StyleRules => ({
  root: {
    height: '100%',
    overflow: 'auto',
  },
  paper: {
    minWidth: 900,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
    height: 'calc(100vh - 64px - 38px)',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(2, 0, 3, 0),
      height: 'unset',
      borderRadius: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    // padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px 0 0 0',
  },
  toolRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
  },
  links: {
    textDecoration: 'none',
  },
  listRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  listItemRoot: {
    width: '100%',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
  },
  loadingBar: {
    width: '100%',
    height: 5,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
  },
  imageContainer: {
    width: 100,
    height: 40,
    borderRadius: 4,
    overflow: 'hidden',
  },
  previewImage: {
    width: 100,
    height: 40,
    objectFit: 'cover',
  },
  funcionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 200,
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 2, 1),
    },
  },
});

type Props = WithStyles<typeof styles>;
type State = {
  eventAlbumList: EventAlbumResponse[];
  count: number;
  page: number;
  pageLoading: boolean;
  deleteUserVisible: boolean;
  confirmLoading: boolean;
  selectedItem: {};
  profile: {};
  loading: boolean;
  filter: GetEventAlbumListParams;
  searchValue: string;
};

type TParams = {};

class EventAlbumListPage extends Component<
  Props & RouteComponentProps<TParams>,
  State
> {
  constructor(Props) {
    super(Props);
    this.state = {
      eventAlbumList: [],
      count: 0,
      page: 1,
      pageLoading: false,
      deleteUserVisible: false,
      confirmLoading: false,
      selectedItem: {},
      profile: {},
      loading: true,
      filter: {
        limit: PAGE_SIZE,
        offset: 0,
        q: '',
      },
      searchValue: '',
    };
    this.pageOnChange = this.pageOnChange.bind(this);
    this.searchTextOnChange = this.searchTextOnChange.bind(this);
    this.setSearch = _.debounce(this.setSearch, 500).bind(this);
  }
  componentDidMount() {
    this.getFilterValuesFromURL();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const preValues = queryString.parse(prevProps.location.search);
    const values = queryString.parse(location.search);
    if (!_.isEqual(preValues, values)) {
      this.getFilterValuesFromURL();
    }
  }

  getFilterValuesFromURL() {
    const { location } = this.props;
    const { search } = location;
    const { filter } = this.state;
    const tempFilter = { ...filter };
    const params = queryString.parse(search);
    const q = (params.q as string) || '';
    const page =
      params && params.page ? parseInt(params.page as string, 10) : 1;
    tempFilter.q = q;
    this.setState(
      { filter: tempFilter, page, searchValue: q ? (q as string) : '' },
      () => this.getEventAlbum(),
    );
  }

  async getEventAlbum() {
    this.setState({ pageLoading: true });
    const { filter, page } = this.state;
    const temp = {
      ...filter,
      offset: (page - 1) * PAGE_SIZE,
    };
    try {
      const eventAlbumRes = await getEventAlbumList(temp);
      this.setState({
        count: eventAlbumRes.count,
        eventAlbumList: eventAlbumRes.rows,
        pageLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ pageLoading: false });
    }
  }

  pageOnChange(event: object, page: number) {
    const { history, location } = this.props;
    const { search } = location;
    const currentUrlParams = new URLSearchParams(search);
    currentUrlParams.set('page', page + '');
    const url = `${location.pathname}?${currentUrlParams.toString()}`;
    this.setState({ page }, () => {
      history.push(url);
    });
  }

  renderData(data: EventAlbumResponse[]) {
    const temp: any = [];
    data.forEach(item => {
      temp.push({
        ...item,
        eventDate: moment(item.eventDate).format('DD-MM-YYYY'),
        date: moment(item.createdAt).format('DD-MM-YYYY'),
      });
    });
    return temp;
  }

  searchTextOnChange(e: React.ChangeEvent<{ value: unknown }>) {
    this.setState({ searchValue: e.target.value as string });
    this.setSearch();
  }

  setSearch() {
    const { location, history } = this.props;
    const { searchValue } = this.state;
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', '1');
    queryParams.set('q', searchValue);
    const url = `${location.pathname}?${queryParams.toString()}`;
    history.push(url);
  }

  render() {
    const { classes } = this.props;
    const {
      pageLoading,
      eventAlbumList,
      count,
      page,
      searchValue,
    } = this.state;
    return (
      <div className={classes.root}>
        <Helmet>
          <title>相片分享 - JPOA</title>
        </Helmet>
        <div className={classes.loadingBar}>
          {pageLoading ? <LinearProgress color="secondary" /> : null}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.titleContainer}>
            <h2>相片分享</h2>
            <Link to="/event-album/new" className={classes.links}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
              >
                新增相片分享
              </Button>
            </Link>
          </div>
          <div className={classes.filterRow}>
            <FormControl className={classes.textFormControl}>
              <InputLabel>活動標題</InputLabel>
              <Input
                placeholder="活動標題"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchOutlinedIcon className={classes.searchIcon} />
                  </InputAdornment>
                }
                value={searchValue}
                onChange={this.searchTextOnChange}
              />
            </FormControl>
          </div>
          <Table
            column={EVENT_ALBUM_TABLE_COLUMN}
            data={this.renderData(eventAlbumList)}
            rowKey="eventAlbumid"
            renderRowButtons={(item: EventAlbumResponse) => {
              return (
                <Link to={`/event-album/edit/${item.eventAlbumid}`}>
                  <Button>
                    <EditIcon style={{ color: 'rgba(0,0,0,0.4)' }} />
                  </Button>
                </Link>
              );
            }}
          />
          <div className={classes.paginationContainer}>
            <Pagination
              count={count}
              page={page}
              onChange={this.pageOnChange}
            />
          </div>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(EventAlbumListPage));
